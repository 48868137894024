import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const deleteExistingMember = ({ memberID }) => {
  const requestURL = `${API_URL}/users/${memberID}`;
  return melior.delete(requestURL);
};

export const useDeleteExistingMember = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
        queryClient.invalidateQueries(["existing-members"], { type: "active" });
    },
    ...config,
    mutationFn: deleteExistingMember,
  });
};
