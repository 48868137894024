import { ExternalProgressPage } from "./ExternalProgressPage";
import { useGetReviewsOverviewData } from "../../api/reviews-overview/getReviewsOverviewData";

export const OverallProgress = ({ skeleton = false, filters }) => {
  const {
    data: reviewsOverviewServerResponse,
    isFetching: isFetchingReviewsOverview,
    isError: isErrorReviewsOverview,
    error: reviewsOverviewError,
  } = useGetReviewsOverviewData({
    params: { ...filters },
    config: { staleTime: 120000 },
  });

  let error = null;
  if (isErrorReviewsOverview) {
    error =
      reviewsOverviewError?.response?.status === 403
        ? "Network Error, Please try again later"
        : reviewsOverviewError?.response?.statusText;
  } else if (reviewsOverviewServerResponse?.data?.questions?.length === 0)
    error = "No data in this duration";

  return (
    <ExternalProgressPage
      data={reviewsOverviewServerResponse?.data}
      loading={isFetchingReviewsOverview}
      error={error}
    />
  );
};
