import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Grid,
  Container,
  Button,
  IconButton,
  Tooltip,
  Collapse,
  Chip,
  Box,
} from "@mui/material";
import {
  AnalyticsOutlined,
  Assignment,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Report,
  SearchTwoTone,
  Warning,
} from "@mui/icons-material";
import { COLORS } from "../../../helpers/Colors";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { AdminContext } from "../contexts/AdminContext";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import ViewTemplate from "./ViewTemplate";
import LoadingPage from "../../../layout/LoadingPage";
import CreateTemplate from "./CreateTemplate";
import EditTemplate from "./EditTemplate";
import SuggestQuestion from "./SuggestQuestion";
import UserSnackBar from "./UserSnackBar";
import { Link } from "react-router-dom";
import { primaryColors } from "../../../helpers/customColors";

const useStyles = makeStyles({
  container: {
    padding: "30px",
  },
  newTabLink: {
    padding: "6px 16px",
    paddingLeft: "6px",
    color: COLORS.black,
  },
  body: {
    maxWidth: "90vw",
    margin: "auto",
  },
  TableContainer: {
    overflow: "auto",
    border: `1px solid ${COLORS.meliorYellowGentle} `,
    maxHeight: "600px",
  },
  table: {
    minWidth: "650px",
    overflow: "auto",
  },
  fixedWidth: {
    minWidth: "160px",
    maxWidth: "160px",
  },
  warning: {
    color: COLORS.failRed,
  },
  safe: {
    color: COLORS.successGreen,
  },
  row: {
    height: "60px",
    "&:nth-of-type(odd)": {
      backgroundColor: COLORS.silver,
    },
  },
  actionButton: {
    textTransform: "none",
    fontSize: "16px",
    backgroundColor: primaryColors.brand[500],
    fontWeight: "normal",
    color: COLORS.black,
    borderRadius: "10px",
    marginTop: "15px",
    float: "right",
    width: "250px",
    alignSelf: "end",
    "&:hover": {
      backgroundColor: COLORS.meliorYellowGentle,
    },
  },
  collapseRow: {
    minHeight: "40px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "15px 0px",
    flexDirection: "column",
    gap: "16px",
  },
  branchChip: {
    margin: "0px 6px",
    background: COLORS.branchManagerChip,
  },
  divisionChip: {
    margin: "0px 6px",
    background: COLORS.divisionManagerChip,
  },
  info: {
    color: COLORS.meliorYellow,
  },
});

const TableHeader = () => (
  <TableHead>
    <TableRow
      style={{ borderBottom: `2px solid ${COLORS.meliorYellowGentle} ` }}
    >
      <TableCell
        style={{
          fontSize: "1.1rem",
          fontFamily: "Avenir-Heavy",
          minWidth: "160px",
          maxWidth: "160px",
        }}
      >
        <p>Template name</p>
      </TableCell>
      <TableCell
        style={{
          fontSize: "1.1rem",
          fontFamily: "Avenir-Heavy",
          minWidth: "160px",
          maxWidth: "160px",
        }}
        align="center"
      >
        <p>ID</p>
      </TableCell>
      <TableCell
        style={{
          fontSize: "1.1rem",
          fontFamily: "Avenir-Heavy",
          minWidth: "160px",
          maxWidth: "160px",
        }}
        align="center"
      >
        <p>Created at</p>
      </TableCell>
      <TableCell
        style={{
          fontSize: "1.1rem",
          fontFamily: "Avenir-Heavy",
          minWidth: "160px",
          maxWidth: "160px",
        }}
        align="right"
      >
        <p>Actions</p>
      </TableCell>
    </TableRow>
  </TableHead>
);

const Row = ({ row, classes, index, setSelectedAction }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow className={classes.row}>
        <TableCell
          className={classes.fixedWidth}
          component="th"
          scope="row"
          align="left"
        >
          {row.name}
        </TableCell>
        <TableCell
          className={classes.fixedWidth}
          component="th"
          scope="row"
          align="center"
        >
          {row.id}
        </TableCell>
        <TableCell
          className={classes.fixedWidth}
          component="th"
          scope="row"
          align="center"
        >
          {new Date(row.created_at).toDateString()}
        </TableCell>
        <TableCell
          className={classes.fixedWidth}
          component="th"
          scope="row"
          align="right"
        >
          {!row.branches.length && !row.divisions.length && (
            <Tooltip title="This template is not assigned" position="top">
              <span>
                <IconButton disabled={true} size="large">
                  <Warning className={classes.info} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="View template details" position="top">
            <IconButton
              onClick={() =>
                setSelectedAction({
                  type: Actions.View,
                  index: index,
                  id: row.id,
                })
              }
              size="large"
            >
              <SearchTwoTone />
            </IconButton>
          </Tooltip>
          <Tooltip title="Assign template to branches/divisions" position="top">
            <IconButton
              onClick={() =>
                setSelectedAction({
                  type: Actions.Edit,
                  index: index,
                  id: row.id,
                })
              }
              size="large"
            >
              <Assignment />
            </IconButton>
          </Tooltip>
          <Tooltip title="Branches/Divisions running this" position="top">
            <IconButton onClick={() => setOpen(!open)} size="large">
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={classes.collapseRow}>
              {!row.branches?.length && !row.divisions?.length ? (
                <Chip label="Not Assigned" />
              ) : (
                ""
              )}

              {!!row.branches?.length && (
                <div>
                  Branches:
                  {row.branches.map((branch) => (
                    <Chip
                      key={branch.id}
                      className={classes.branchChip}
                      label={branch.name}
                    />
                  ))}
                </div>
              )}
              {!!row.divisions?.length && (
                <div>
                  Divisions:
                  {row.divisions.map((division) => (
                    <Chip
                      key={division.id}
                      className={classes.divisionChip}
                      label={division.name}
                    />
                  ))}
                </div>
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Actions = {
  View: "View",
  Edit: "Edit",
  Create: "Create",
  SuggestQestion: "SuggestQestion",
  None: "None",
};

export const TemplatesAdminPage = ({ disableEdits = false }) => {
  const classes = useStyles();

  const [error, setError] = useState(false);

  const { user, isDemo, isFacilityManager } = useContext(AuthenticationContext);

  const { facilityTemplates, getFacilityTemplatesHandler } =
    useContext(AdminContext);

  const [selectedAction, setSelectedAction] = useState({
    type: Actions.None,
    index: -1,
    id: -1,
  });

  const [tempMessage, setTempMessage] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    if (!user) return;
    getFacilityTemplatesHandler();
  }, [user]);

  useEffect(() => {
    if (!tempMessage) return;
    setSnackBarOpen(true);
  }, [tempMessage]);

  return (
    <Container className={classes.container} maxWidth="xl">
      <Grid className={classes.body} container direction="column">
        <Grid container justifyContent="center">
          {!error.isError ? null : (
            <UserErrorSuccessFormDisplay
              color={COLORS.white}
              type="error"
              message={error.errorMessage}
              width="90%"
              margin="10px auto"
              padding="15px 15px"
              bg={COLORS.failRed}
            />
          )}
          {!facilityTemplates ? (
            <div>
              <LoadingPage height="70vh" />
            </div>
          ) : (
            <TableContainer className={classes.TableContainer}>
              {/*           
              TEMPLATES TABLE
              */}
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHeader
                  showActions={true}
                  disableEdits={disableEdits}
                  isFacilityManager={isFacilityManager}
                  isDemo={isDemo}
                />
                <TableBody>
                  {facilityTemplates.map((row, index) => (
                    <Row
                      key={row.id}
                      index={index}
                      row={row}
                      classes={classes}
                      disableEdits={disableEdits}
                      isDemo={isDemo}
                      isFacilityManager={isFacilityManager}
                      setSelectedAction={setSelectedAction}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Button
          className={classes.actionButton}
          onClick={() =>
            setSelectedAction({ type: Actions.Create, index: -1, id: -1 })
          }
        >
          Create new template
        </Button>
        <Button
          className={classes.actionButton}
          onClick={() =>
            setSelectedAction({
              type: Actions.SuggestQestion,
              index: -1,
              id: -1,
            })
          }
        >
          Suggest a question
        </Button>
      </Grid>
      {selectedAction.type === Actions.View && (
        <ViewTemplate
          action={selectedAction.type}
          template={
            selectedAction.type === Actions.None
              ? null
              : facilityTemplates[selectedAction.index]
          }
          disableEdits={disableEdits}
        />
      )}
      {selectedAction.type === Actions.Create && (
        <CreateTemplate
          disableEdits={disableEdits}
          cancelAdd={() =>
            setSelectedAction({ type: Actions.None, index: -1, id: -1 })
          }
        />
      )}
      {selectedAction.type === Actions.Edit && (
        <EditTemplate
          templateId={selectedAction.id}
          templateName={facilityTemplates[selectedAction.index].name}
          disableEdits={disableEdits}
          cancelEdit={() =>
            setSelectedAction({ type: Actions.None, index: -1, id: -1 })
          }
        />
      )}
      {selectedAction.type === Actions.SuggestQestion && (
        <SuggestQuestion
          disableEdits={disableEdits}
          cancelEdit={() =>
            setSelectedAction({ type: Actions.None, index: -1, id: -1 })
          }
          setTempMessage={setTempMessage}
        />
      )}
      <UserSnackBar
        open={snackBarOpen}
        message={tempMessage}
        closeSnackBar={() => {
          setSnackBarOpen(false);
          setTempMessage("");
        }}
        backgroundColor={COLORS.successGreenGentle}
      />
    </Container>
  );
};

export default TemplatesAdminPage;
