import { OverallViewTable } from "../../../../revamped-overall-view/components/shared/OverallViewTable";
import { ExistingMembersTableRowDetails } from "./ExistingMembersTableRowDetails";
import { ExistingMembersTableEditDetails } from "./ExistingMembersTableEditDetails";

import { DeleteExistingMember } from "./DeleteExistingMember";
import EditIcon from "@mui/icons-material/Edit";
import { useListAPIsStateManagement } from "../../../../../reusable/hooks/useListAPIStateManagement";
import { useGetUserRoles } from "../../../api/members";
import { Box, IconButton, Tooltip } from "@mui/material";
import { primaryColors } from "../../../../../helpers/customColors";
import { useState } from "react";
import { EmptyTableView } from "../../../reusable/EmptyTableView";
import { TableContainer } from "../../../../revamped-overall-view/components/shared/TableContainer";
import {
  defaultExistingMembersListParams,
  useGetExistingMembers,
} from "../../../api/members/getExistingMembers";
import { InvitationDialogButton } from "../InvitationDialogButton";

export const ExistingMembersTable = () => {
  const [rowPanelDetailsType, setRowPanelDetailsType] = useState(
    rowPanelDetailsTypes.default
  );

  const {
    apiListArgsState,
    debouncedSearchText,
    updatePageHandler,
    searchQueryHandler,
  } = useListAPIsStateManagement({
    initialState: {
      ...defaultExistingMembersListParams,
    },
  });

  const {
    data: existingMembersResponse,
    isFetching: isFetchingMembers,
    isError: membersFetchingError,
    isLoading: isLoadingExistingMembers,
  } = useGetExistingMembers({
    params: { page: apiListArgsState.page, searchValue: debouncedSearchText },
  });

  const { data: userRolesResponse, isLoading: isLoadingUserRoles } =
    useGetUserRoles();

  function renderDynamicRowPanelDetails({ rowPanelDetailsTypeArg, row }) {
    switch (rowPanelDetailsTypeArg) {
      case rowPanelDetailsTypes.default:
        return <ExistingMembersTableRowDetails memberDetails={row.original} />;
      case rowPanelDetailsTypes.edit:
        return <ExistingMembersTableEditDetails memberDetails={row.original} />;
      default: {
        return null;
      }
    }
  }

  const membersTableColumns = [
    {
      header: "Email",
      id: "email",
      accessorKey: "email",
      accessorFn: function renderUserEmail(memberData) {
        if (memberData.email.length > 0) return memberData.email;
        else return "N/A";
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "Username",
      accessorKey: "username",
      minSize: 50,
      size: 300,
    },
    {
      header: "Role",
      id: "role",
      accessorFn: function renderUserRole(memberData) {
        for (let userRole of userRolesResponse.data) {
          if (userRole.value === memberData.role) return userRole.label;
        }
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "",
      id: "actions",
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      accessorFn: function renderMemberActions(memberData) {
        return (
          <Box>
            <Tooltip title="Edit Member">
              <IconButton
                sx={{ padding: "10px", borderRadius: "16px" }}
                aria-label="edit"
                onClick={() =>
                  setRowPanelDetailsType(rowPanelDetailsTypes.edit)
                }
              >
                <EditIcon
                  sx={{
                    color: primaryColors.base.black,
                    height: "20px",
                    width: "20px",
                  }}
                />
              </IconButton>
            </Tooltip>
            <DeleteExistingMember memberData={memberData} />
          </Box>
        );
      },
      minSize: 50,
      size: 350,
    },
  ];

  const allMembersTableView = (
    <OverallViewTable
      columns={membersTableColumns}
      data={existingMembersResponse?.data.results || []}
      totalRecords={existingMembersResponse?.data.count}
      isLoading={isLoadingExistingMembers || isLoadingUserRoles}
      isError={membersFetchingError}
      externalPaginationProps={{
        externalCurrentPage: apiListArgsState.page,
        updateExternalCurrentPage: updatePageHandler,
        isFetching: isFetchingMembers,
      }}
      uniqueColumnName="username"
      resetRowPanelDetailsTypeCallback={function setRowPanelDetailsTypeToDefaultOnClose() {
        setRowPanelDetailsType(rowPanelDetailsTypes.default);
      }}
      renderRowDetails={({ row }) => {
        return renderDynamicRowPanelDetails({
          rowPanelDetailsTypeArg: rowPanelDetailsType,
          row,
        });
      }}
      searchProps={{
        enabled: true,
        placeholder: "Search by Username or Email",
        onSearchCallback: searchQueryHandler,
      }}
    />
  );

  const noMembersView = existingMembersResponse && (
    <EmptyTableView
      title="No new members have been invited"
      description="Get your team on board and let the journey begin! There are various
      roles available, so you can assign each member to a specific branch
      and division."
      actionButton={
        <InvitationDialogButton
          disabled={
            existingMembersResponse.data.count >=
            existingMembersResponse.data.users_max_count
          }
        />
      }
    />
  );

  let membersTableView = null;

  if (
    isLoadingExistingMembers ||
    isLoadingUserRoles ||
    existingMembersResponse?.data.results.length > 0
  ) {
    membersTableView = allMembersTableView;
  }

  if (
    !isLoadingExistingMembers &&
    !isLoadingUserRoles &&
    existingMembersResponse?.data.results.length === 0
  ) {
    membersTableView = noMembersView;
  }
  
  return (
    <TableContainer
      label={"Existing Team Members"}
      tableActionsNextToName={
        <Box display="flex" flexDirection={"row"} alignItems="center">
          {existingMembersResponse && (
            <InvitationDialogButton
              disabled={
                existingMembersResponse.data.count >=
                existingMembersResponse.data.users_max_count
              }
            />
          )}
        </Box>
      }
    >
      {membersTableView}
    </TableContainer>
  );
};
const rowPanelDetailsTypes = {
  default: "default",
  edit: "edit",
};
