import { Grid, Typography } from "@mui/material";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../../helpers/customFont";
import { primaryColors } from "../../../../../helpers/customColors";
import { SeeMoreBranchesDialogButton } from "./SeeMoreBranchesDialogButton";
import { useGetIssues, useGetSilentTimes } from "../../../api/members";

export const ExistingMembersTableRowDetails = ({ memberDetails }) => {
  const { data: issuesResponse, isLoading: isLoadingIssues } = useGetIssues();
  const { data: silentTimesResponse, isLoading: isLoadingSilentTimes } =
    useGetSilentTimes();

  const getMemberIssues = (memeberIssuesList) => {
    let memberIssuesString = "";
    if (!isLoadingIssues && issuesResponse) {
      memeberIssuesList.forEach((issueID) => {
        issuesResponse.data.forEach((issueData) => {
          if (issueData.id === issueID)
            memberIssuesString += `${issueData.name}, `;
        });
      });
    }
    return memberIssuesString.slice(0, -2);
  };
  const getMemberSilentTime = (memberSilentTime) => {
    let memberTimeString = "";
    if (!isLoadingSilentTimes && silentTimesResponse) {
      silentTimesResponse.data.forEach((timeData) => {
        if (timeData.value == memberSilentTime)
          memberTimeString = timeData.label;
      });
    }
    return memberTimeString;
  };

  return (
    <Grid
      container
      bgcolor={primaryColors.base.white}
      display="flex"
      alignItems="flex-start"
      padding="24px"
      gap="40px"
      height="fit-content"
      columns={12}
      width="100%"
    >
      <Grid item xs={12}>
        {MemberInfo(memberDetails, getMemberIssues, getMemberSilentTime)}
      </Grid>
      <Grid item xs={12}>
        {MemberBranchesAndDivisions(memberDetails)}
      </Grid>
    </Grid>
  );
};

const MemberInfoItem = (label, value) => {
  return (
    <Grid item xs={4} display="flex" flexDirection="column" gap="16px">
      <Typography
        sx={{
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.bold,
          color: primaryColors.gray[700],
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontSize: fontSizeVariations["text-md"],
          fontWeight: fontWeightVariations.medium,
          color: primaryColors.gray[500],
        }}
      >
        {value}
      </Typography>
    </Grid>
  );
};

const MemberInfo = (memberDetails, getMemberIssues, getMemberSilentTime) => {
  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {MemberInfoItem(
        "Phone Number",
        memberDetails.phone_number ? memberDetails.phone_number : "None"
      )}
      {MemberInfoItem(
        "Issue",
        memberDetails.wa_profile
          ? getMemberIssues(memberDetails.wa_profile.issues)
          : "None"
      )}
      {MemberInfoItem(
        "Silent time",
        memberDetails.wa_profile
          ? getMemberSilentTime(memberDetails.wa_profile.silent_time)
          : "None"
      )}
    </Grid>
  );
};

const MemberBranchesAndDivisionsItem = (branch, divisions) => {
  return (
    <Grid
      container
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      gap="16px"
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: fontSizeVariations["text-sm"],
            fontWeight: fontWeightVariations.bold,
            color: primaryColors.gray[700],
          }}
        >
          {branch}
        </Typography>
      </Grid>
      <Grid
        container
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
      >
        {divisions.slice(0, 3).map((division) => (
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            gap="16px"
            key={division.id}
          >
            <Typography
              sx={{
                fontSize: fontSizeVariations["text-md"],
                fontWeight: fontWeightVariations.medium,
                color: primaryColors.gray[500],
              }}
            >
              {division}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const MemberBranchesAndDivisions = (memberDetails) => {
  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="space-between"
      gap={"40px"}
    >
      {memberDetails.branches.slice(0, 2).map((branch) => {
        let branchDivisions = [];
        // eslint-disable-next-line array-callback-return
        memberDetails.divisions.map((division) => {
          if (division.branch_name === branch.name)
            return branchDivisions.push(division.name);
        });
        return MemberBranchesAndDivisionsItem(branch.name, branchDivisions);
      })}

      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <SeeMoreBranchesDialogButton memberDetails={memberDetails} />
      </Grid>
    </Grid>
  );
};
