import { useState } from "react";
import { Grid, RadioGroup, Typography, Switch, Box } from "@mui/material";

import { withStyles } from "@mui/styles";
import { primaryColors } from "../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import { ReactComponent as Logo } from "../../assets/melior-logo-black.svg";
import UserErrorSuccessFormDisplay from "../../reusable/components/UserErrorSuccessFormDisplay";
import { LoadingButton } from "@mui/lab";
import { ONLINE_SURVEY_TYPES } from "./OnlineSurveyController";

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: "2px",
    color: primaryColors.base.white,
    "&$checked": {
      transform: "translateX(12px)",
      color: primaryColors.base.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: primaryColors.gray[100],
        borderColor: primaryColors.gray[100],
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${primaryColors.gray[100]}`,
    borderRadius: 12,
    height: "auto",
    opacity: 1,
    backgroundColor: primaryColors.brand[500],
  },
  checked: {},
}))(Switch);

export const OnlineSurveyWelcomeCard = (props) => {
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const [type, setType] = useState("");

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const getReviewButtonText = () => {
    let btnText;
    if (props.surveyType === ONLINE_SURVEY_TYPES.review) {
      props.language ? (btnText = "ابدأ") : (btnText = "Start");
    } else if (props.surveyType === ONLINE_SURVEY_TYPES.both) {
      props.language ? (btnText = "تقييم") : (btnText = "Review");
    }
    return btnText;
  };
  const getComplaintButtonText = () => {
    let btnText;
    if (props.surveyType === ONLINE_SURVEY_TYPES.complaint) {
      props.language ? (btnText = "ابدأ") : (btnText = "Start");
    } else if (props.surveyType === ONLINE_SURVEY_TYPES.both) {
      props.language ? (btnText = "شكوى") : (btnText = "Complaint");
    }
    return btnText;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      py="20px"
      px="15px"
      height="80vh"
      textAlign="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gap={2}
      >
        {props.facilityData.image ? (
          <Box display="flex" width="100%" justifyContent="center">
            <img
              src={props.facilityData.image}
              alt={props.facilityData.name}
              style={{
                width: "90%",
                maxWidth: animationAndLogoDimentions.width,
                maxHeight: animationAndLogoDimentions.height,
                display: "block",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>
        ) : (
          <Logo
            style={{
              width: "90%",
              maxWidth: animationAndLogoDimentions.width,
              maxHeight: animationAndLogoDimentions.height,
              display: "block",
              height: "auto",
              objectFit: "contain",
              visibility: "hidden",
            }}
          />
        )}
        {!error.isError && !props.error.isError ? null : (
          <UserErrorSuccessFormDisplay
            color={primaryColors.base.black}
            type="error"
            message={
              error.isError ? error.errorMessage : props.error.errorMessage
            }
            width="90%"
            margin="auto"
          />
        )}

        <Box width="95%" height="fit-content" gap={1} dir="auto">
          <Typography
            sx={{
              width: "95%",
              margin: "0px auto",
              fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
              fontSize: fontSizeVariations["display-xs"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.gray[900],
            }}
          >
            {props.landingPageWelcome?.headline
              ? props.landingPageWelcome?.headline
              : `${
                  props.language
                    ? "مرحبا بك"
                    : props.landingPageWelcome?.headline || "Welcome"
                }${props.facilityData.name}`}
          </Typography>
          <Typography
            sx={{
              margin: "16px auto 0px",
              fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
              fontSize: fontSizeVariations["text-xl"],
              fontWeight: fontWeightVariations.medium,
              color: primaryColors.gray[900],
            }}
          >
            {props.language
              ? "نود أن نسمع منك"
              : props.landingPageWelcome?.short_qoute ||
                "Would love to hear from you"}
          </Typography>
        </Box>
      </Box>
      {props.verified && (
        <Box>
          <RadioGroup name="survey type" value={type} onChange={handleChange}>
            <Box display={"flex"} flexDirection="column" gap={"16px"}>
              {props.surveyType !== ONLINE_SURVEY_TYPES.complaint && (
                <LoadingButton
                  loading={
                    props.templateId === undefined || props.templateId === null
                  }
                  disabled={
                    props.templateId === undefined || props.templateId === null
                  }
                  onClick={() => props.start(ONLINE_SURVEY_TYPES.review)}
                  sx={{
                    fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                    fontSize: fontSizeVariations["text-md"],
                    fontWeight: fontWeightVariations.medium,
                    color: primaryColors.gray[800],
                    padding: "10px 16px",
                    margin: "0px",
                    borderRadius: "8px",
                    background: primaryColors.brand[500],
                    textTransform: "none",
                    "&:hover": {
                      background: primaryColors.brand[500],
                    },
                  }}
                >
                  {getReviewButtonText()}
                </LoadingButton>
              )}

              {props.surveyType !== ONLINE_SURVEY_TYPES.review && (
                <LoadingButton
                  loading={
                    props.templateId === undefined || props.templateId === null
                  }
                  disabled={
                    props.templateId === undefined || props.templateId === null
                  }
                  sx={{
                    fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                    fontSize: fontSizeVariations["text-md"],
                    fontWeight: fontWeightVariations.medium,
                    color: primaryColors.brand[800],
                    padding: "10px 16px",
                    margin: "0px",
                    borderRadius: "8px",
                    background:
                      props.surveyType === ONLINE_SURVEY_TYPES.both
                        ? primaryColors.error[50]
                        : primaryColors.brand[500],
                    textTransform: "none",
                    "&:hover": {
                      background:
                        props.surveyType === ONLINE_SURVEY_TYPES.both
                          ? primaryColors.error[50]
                          : primaryColors.brand[500],
                    },
                  }}
                  onClick={() => props.start(ONLINE_SURVEY_TYPES.complaint)}
                >
                  {getComplaintButtonText()}
                </LoadingButton>
              )}
            </Box>
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};

export const animationAndLogoDimentions = {
  width: "330px",
  height: "182px",
};
