import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const defaultExistingMembersListParams = {
  page: 1,
};

export const getExistingMembers = (params) => {
  const { page, searchValue } = params;

  const requestURL = `${API_URL}/users`;
  
  return melior.get(requestURL, {
    params: { page: page, search: searchValue },
  });
};

export const useGetExistingMembers = (
  { params, config } = {
    params: defaultExistingMembersListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["existing-members", params],
    queryFn: () => getExistingMembers(params),
    keepPreviousData: true,
  });
};
