import { useEffect, useState } from "react";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { OverallViewTable } from "../../../revamped-overall-view/components/shared/OverallViewTable";
import { EmptyTableView } from "../../reusable/EmptyTableView";
import { primaryColors } from "../../../../helpers/customColors";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { TableContainer } from "../../../revamped-overall-view/components/shared/TableContainer";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useGetBranchDetails } from "../../api/branches-divisions/getBranchDetails";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import { DeleteBranch } from "./DeleteBranch";
import { CreateBranchDialog } from "./CreateBranchDialog";
import { EditBranchDialog } from "./EditBranchDialog";
import { FormTextInput } from "../../../../reusable/components/form-inputs/controlled";
import { FormProvider } from "react-hook-form";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import { useUpdateBranch } from "../../api/branches-divisions/updateBranch";
import { useContext } from "react";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { LoadingButton } from "@mui/lab";
import {
  defaultBranchesListParams,
  useGetAllBranchesPaginated,
} from "../../api/branches-divisions/getAllBranchesPaginated";

export const BranchesAndDivisionsTable = () => {
  const [rowPanelDetailsType, setRowPanelDetailsType] = useState(
    rowPanelDetailsTypes.default
  );

  const { apiListArgsState, updatePageHandler } = useListAPIsStateManagement({
    initialState: {
      ...defaultBranchesListParams,
    },
  });

  const {
    data: branchesResponse,
    isLoading: isLoadingBranchesResponse,
    isFetching: isFetchingBranchesResponse,
    isError: errorInBranchesResponse,
  } = useGetAllBranchesPaginated({ params: { ...apiListArgsState } });

  const reachedBranchesMaxLimit =
    branchesResponse?.data.branches_max_count === branchesResponse?.data.count;
  const maxDivisionsLimit = branchesResponse?.data.divisions_max_count;

  const membersTableColumns = [
    {
      header: "Branch Name",
      accessorKey: "name",
      minSize: 50,
      size: 300,
    },
    {
      header: "Branch Color",
      accessorFn: function renderBranchName(branchData) {
        return (
          <Box
            width="24px"
            height="24px"
            borderRadius="50%"
            bgcolor={branchData.color}
          ></Box>
        );
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "Number of Divisions",
      accessorFn: function renderNumberOfDivisionsOfBranch(branchData) {
        return branchData.divisions.length;
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "",
      id: "actions",
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      accessorFn: function renderBranchActions(branchData) {
        return (
          <Box>
            <Tooltip title="Quick Edit">
              <IconButton
                sx={{ padding: "10px", borderRadius: "16px" }}
                aria-label="edit"
                onClick={() =>
                  setRowPanelDetailsType(rowPanelDetailsTypes.edit)
                }
              >
                <EditIcon
                  sx={{
                    color: primaryColors.base.black,
                    height: "20px",
                    width: "20px",
                  }}
                />
              </IconButton>
            </Tooltip>
            <DeleteBranch branchData={branchData} />
          </Box>
        );
      },
      minSize: 50,
      size: 350,
    },
  ];

  const noBranchesAndDivisionsView = (
    <EmptyTableView
      title="Start by adding your branches"
      description="Set up your branches and divisions to easily monitor performance and keep track of your progress."
      actionButton={
        <CreateBranchDialog
          maxDivisionsLimit={maxDivisionsLimit}
          reachedMaxBranchesLimit={reachedBranchesMaxLimit}
        />
      }
    />
  );

  const branhesAndDivisionsTableView = (
    <OverallViewTable
      columns={membersTableColumns}
      data={branchesResponse?.data.results || []}
      totalRecords={branchesResponse?.data.count}
      isLoading={isLoadingBranchesResponse}
      isError={errorInBranchesResponse}
      externalPaginationProps={{
        externalCurrentPage: apiListArgsState.page,
        updateExternalCurrentPage: updatePageHandler,
        isFetching: isFetchingBranchesResponse,
      }}
      resetRowPanelDetailsTypeCallback={function setRowPanelDetailsTypeToDefaultOnClose() {
        setRowPanelDetailsType(rowPanelDetailsTypes.default);
      }}
      renderRowDetails={({ row }) => {
        return renderDynamicRowPanelDetails({
          rowPanelDetailsTypeArg: rowPanelDetailsType,
          row,
        });
      }}
    />
  );

  function renderDynamicRowPanelDetails({ rowPanelDetailsTypeArg, row }) {
    switch (rowPanelDetailsTypeArg) {
      case rowPanelDetailsTypes.default:
        return (
          <BranchRowDetailsPanel
            branchID={row.original.id}
            maxDivisionsLimit={maxDivisionsLimit}
          />
        );
      case rowPanelDetailsTypes.edit:
        return (
          <QuickEditBranchDetailsPanel
            branchID={row.original.id}
            maxDivisionsLimit={maxDivisionsLimit}
          />
        );
      // return <ExistingMembersTableEditDetails memberDetails={row.original} />;
      default: {
        return null;
      }
    }
  }

  let branchesAndDivisionsSectionView = null;

  if (isLoadingBranchesResponse || branchesResponse?.data.results.length > 0) {
    branchesAndDivisionsSectionView = branhesAndDivisionsTableView;
  }

  if (
    !isLoadingBranchesResponse &&
    branchesResponse?.data.results.length === 0
  ) {
    branchesAndDivisionsSectionView = noBranchesAndDivisionsView;
  }

  return (
    <TableContainer
      label={"Branches & Divisions"}
      tableActionsNextToName={
        <CreateBranchDialog
          reachedMaxBranchesLimit={reachedBranchesMaxLimit}
          maxDivisionsLimit={maxDivisionsLimit}
        />
      }
    >
      {branchesAndDivisionsSectionView}
    </TableContainer>
  );
};

const rowPanelDetailsTypes = {
  default: "default",
  edit: "edit",
};

const BranchRowDetailsPanel = ({ branchID, maxDivisionsLimit }) => {
  const {
    data: branchDetailsResponse,
    isLoading: isLoadingBranchDetailsResponse,
  } = useGetBranchDetails({
    params: { branchID },
  });

  return (
    <Box display="flex" flexDirection="column" gap={4} flexWrap="wrap" p={2}>
      <IsLoadingContainer isLoading={isLoadingBranchDetailsResponse}>
        <Box display="flex" gap={10}>
          {branchDetailsResponse?.data.divisions.map(
            function renderDivisionName(divisionData, index) {
              return (
                <Box display="flex" flexDirection="column" key={index}>
                  <Typography variant="text-sm" fontWeight="bold">
                    {`#${index + 1} Division Name`}
                  </Typography>
                  <Typography variant="text-md">{divisionData.name}</Typography>
                </Box>
              );
            }
          )}
        </Box>
        {branchDetailsResponse && (
          <EditBranchDialog
            branchData={branchDetailsResponse.data}
            maxDivisionsLimit={maxDivisionsLimit}
          />
        )}
      </IsLoadingContainer>
    </Box>
  );
};

const QuickEditBranchDetailsPanel = ({
  branchID,
  maxDivisionsLimit,
  resetQuickBranchEditForm,
}) => {
  const {
    data: branchDetailsResponse,
    isLoading: isLoadingBranchDetailsResponse,
  } = useGetBranchDetails({
    params: { branchID },
  });

  const reachedMaxDivisionsLimit =
    branchDetailsResponse?.data.divisions.length === maxDivisionsLimit;

  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexWrap="wrap"
        width="100%"
        gap={4}
        p={2}
      >
        <IsLoadingContainer isLoading={isLoadingBranchDetailsResponse}>
          {branchDetailsResponse && !isLoadingBranchDetailsResponse && (
            <MinimalEditBranchFormForTableRowPanel
              branchDetailsResponse={branchDetailsResponse}
            />
          )}
          {branchDetailsResponse && !reachedMaxDivisionsLimit && (
            <EditBranchDialog
              branchData={branchDetailsResponse.data}
              maxDivisionsLimit={maxDivisionsLimit}
            />
          )}
        </IsLoadingContainer>
      </Box>
    </Box>
  );
};

const MinimalEditBranchFormForTableRowPanel = ({ branchDetailsResponse }) => {
  const { user } = useContext(AuthenticationContext);

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    // divisions: yup.array().of(yup.object().shape({ name: yup.string() })),
  });

  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: {
      ...branchDetailsResponse.data,
    },
  });

  const { reset, handleSubmit, formState, control, watch } =
    reactHookFormMethods;

  const updateBranchMutation = useUpdateBranch();

  const branchName = watch("name");

  useEffect(
    function updateFormStateToMatchLatestBranchResponse() {
      reset({ ...branchDetailsResponse.data });
    },
    [branchDetailsResponse.data, reset]
  );

  return (
    <FormProvider {...reactHookFormMethods}>
      <form
        method="POST"
        onSubmit={handleSubmit(async (updatedBranchData) => {
          const updatedBranchReadyToSubmit = {
            id: updatedBranchData.id,
            name: updatedBranchData.name,
            divisions: updatedBranchData.divisions,
          };
          updateBranchMutation.mutateAsync(updatedBranchReadyToSubmit);
        })}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box
            display="flex"
            flexDirection="column"
            width="fit-content"
            gap={2}
          >
            <Box display="flex" flexDirection="column">
              <Typography
                sx={{
                  color: primaryColors.gray[700],
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations.bold,
                  marginBottom: "6px",
                }}
              >
                Branch
              </Typography>
              <FormTextInput
                name="name"
                placeholder="Branch Name"
                type="text"
                sx={{
                  color: primaryColors.gray[500],
                  backgroundColor: primaryColors.base.white,
                  border: `1px solid ${primaryColors.gray[300]}`,
                  borderRadius: "8px",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} borderRadius={2}>
              <Box display="flex" gap={4}>
                {/* No condition here whether to render or not
                because the default for a branch is to have at least one division */}
                <DivisionInput index={0} />

                {branchDetailsResponse.data.divisions.length > 1 && (
                  <DivisionInput index={1} />
                )}
                {branchDetailsResponse.data.divisions.length > 2 && (
                  <DivisionInput index={2} />
                )}
              </Box>
            </Box>
          </Box>

          <LoadingButton
            variant="contained"
            type="submit"
            sx={{
              textTransform: "capitalize",
              color: primaryColors.base.white,
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: "bold",
            }}
            disabled={branchName === ""}
            loading={updateBranchMutation.isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
};

const DivisionInput = ({ index }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography
        sx={{
          color: primaryColors.gray[700],
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.bold,
          marginBottom: "6px",
        }}
      >
        #{index + 1} Division Name
      </Typography>
      <FormTextInput
        name={`divisions.${index}.name`}
        placeholder="Branch Name"
        type="text"
        sx={{
          color: primaryColors.gray[500],
          backgroundColor: primaryColors.base.white,
          border: `1px solid ${primaryColors.gray[300]}`,
          borderRadius: "8px",
        }}
      />
    </Box>
  );
};
