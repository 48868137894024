import { useContext, useMemo, useReducer, useState } from "react";
import { Box, IconButton, MenuItem, Menu } from "@mui/material";
import { OverallViewTable } from "../shared/OverallViewTable";
import { ReviewTableRowDetails } from "./ReviewTableRowDetails";
import { TableContainer } from "../shared/TableContainer";
import { DynamicSearchInput } from "../shared/DynamicSearchInput";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import {
  defaultReviewsListParams,
  useGetReviews,
} from "../../api/reviews/getReviews";
import "./table-styles.css";
import { StarReview } from "./StarReview";
import SaveAs from "./SaveAs";
import moment from "moment";
import { ArchiveReview } from "./ArchiveReview";
import { useGetTemplateQuestions } from "../../api/reviews/getTemplateQuestions";
import { AuthenticationContext } from "../../../login";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { primaryColors } from "../../../../helpers/customColors";

export const ReviewsTable = ({
  templateData,
  selectedFiltersState,
  moveUp,
  moveDown,

  // No longer needed as there's no archived and starred tabs any more.
  starred,
  archived,
}) => {
  const [selectedReviewsIDs, setSelectedReviewsIDs] = useState([]);

  const { data: reviewsListQuestionsResponse } = useGetTemplateQuestions({
    params: { templateID: templateData.id },
  });

  const {
    apiListArgsState,
    updatePageHandler,
    updatePageSizeHandler,
    initiateTypedSearchHandler,
  } = useListAPIsStateManagement({
    initialState: {
      ...defaultReviewsListParams,
    },
  });

  const dateFrom = selectedFiltersState.date_from;
  const dateTo = selectedFiltersState.date_to;
  const selectedDivisions = selectedFiltersState.divisions.join(",");
  const selectedChannels = selectedFiltersState.channels.join(",");
  const selectedQuestions = selectedFiltersState.questions.join(",");

  const reviewsQueryParams = {
    ...apiListArgsState,
    search_text: undefined,
    search_type: undefined,
    date_from: apiListArgsState.search_text ? undefined : dateFrom,
    date_to: apiListArgsState.search_text ? undefined : dateTo,
    divisions: apiListArgsState.search_text ? undefined : selectedDivisions,
    channels: apiListArgsState.search_text ? undefined : selectedChannels,
    questions: apiListArgsState.search_text ? undefined : selectedQuestions,
    comment_text: apiListArgsState.search_text
      ? undefined
      : selectedFiltersState.withComment,
    archived: selectedFiltersState.archived,
    bookmarked: selectedFiltersState.bookmarked,
    templates: templateData.id,
    // bookmarked: apiListArgsState.search_text ? undefined : starred,
    // archived: apiListArgsState.search_text ? undefined : archived,
  };

  reviewsQueryParams[apiListArgsState.search_type] =
    apiListArgsState.search_text;

  const {
    data: reviewsResponse,
    isFetching: isFetchingReviews,
    isError: reviewsFetchingError,
    isLoading: isLoadingReviews,
  } = useGetReviews({
    params: { ...reviewsQueryParams },
  });

  const { reviewsTableColumns } = useReviewsTableColumns();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOptionsMenuOpened = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return !reviewsResponse ? null : (
    <TableContainer
      label={templateData.name}
      tableActionsNextToName={
        <Box display="flex" alignItems="center" gap={2}>
          <SaveAs
            queryStringsForAPI={reviewsQueryParams}
            selectedReviewsIDs={
              selectedReviewsIDs === "all" ? [] : selectedReviewsIDs
            }
            disabled={selectedReviewsIDs?.length === 0}
            questionsDataToRender={reviewsListQuestionsResponse?.data}
          />
          <DynamicSearchInput
            initiateSearchCallback={({ type, value }) =>
              initiateTypedSearchHandler({ type, value })
            }
            resetSearchCallback={() =>
              initiateTypedSearchHandler({ type: "", value: "" })
            }
            withPhone
          />
          <Box>
            <IconButton
              id="move-options"
              aria-controls={isOptionsMenuOpened ? "long-menu" : undefined}
              aria-expanded={isOptionsMenuOpened ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "move-options",
              }}
              anchorEl={anchorEl}
              open={isOptionsMenuOpened}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: "fit-content",
                  width: "20ch",
                },
              }}
            >
              <MenuItem
                onClick={function moveUpAndClose() {
                  moveUp();
                  handleClose();
                }}
                disabled={moveUp === undefined}
              >
                Move Up
              </MenuItem>
              <MenuItem
                onClick={function moveDownAndClose() {
                  moveDown();
                  handleClose();
                }}
                disabled={moveDown === undefined}
              >
                Move Down
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      }
      //   tableFiltersBelowName={questionsFilter}
    >
      <OverallViewTable
        columns={reviewsTableColumns}
        data={reviewsResponse.data.results.map(
            function setRowBackgroundColorForArchivedReview(reviewData) {
              return {
                ...reviewData,
                styles: {
                  backgroundColor: reviewData.archived
                    ? primaryColors.error[100]
                    : "",
                },
              };
            }
          )}
        totalRecords={reviewsResponse?.data.total_count}
        isLoading={isLoadingReviews}
        isError={reviewsFetchingError}
        externalPaginationProps={{
          externalCurrentPage: apiListArgsState.page,
          updateExternalCurrentPage: updatePageHandler,

          externalPageSize: apiListArgsState.pageSize,
          updatePageSizeHandler,

          isFetching: isFetchingReviews,
        }}
        uniqueColumnName="id"
        rowsSelectionHandling={{
          updaterHandler: function updateSelectedReviewsIDs(
            latestSelectedRowsIDs
          ) {
            setSelectedReviewsIDs(latestSelectedRowsIDs);
          },
        }}
        renderRowDetails={({ row }) => {
          return (
            <ReviewTableRowDetails
              reviewDetails={row.original}
              isExpanded={row.getIsExpanded()}
            />
          );
        }}
        withRowSelection
      />
    </TableContainer>
  );
};

const reviewsTableSelecterFiltersInitialState = {
  // Default Date Range is the past Week
  questions: [],
};

const reviewsTableFiltersReducer = (state, action) => {
  switch (action.type) {
    case reviewTableFiltersDispatchActions["UPDATE-SELECTED-QUESTIONS"]: {
      return {
        ...state,
        questions: action.payload.questions,
      };
    }
    default: {
      return state;
    }
  }
};

export const reviewTableFiltersDispatchActions = {
  "UPDATE-SELECTED-QUESTIONS": "UPDATE-SELECTED-QUESTIONS",
};

export const useReviewsTableColumns = () => {
  const { canArchiveReviews } = useContext(AuthenticationContext);

  const reviewsTableColumns = useMemo(
    () => [
      {
        header: "",
        id: "star-action",
        accessorFn: function renderReviewStarAction(reviewData) {
          return <StarReview reviewRecord={reviewData} />;
        },
        size: 50,
      },
      {
        header: "ID",
        id: "id",
        accessorKey: "id",
        size: 50,
      },
      {
        header: "Visit Date",
        id: "visit_date",
        accessorFn: function renderComplaintVisitDateColumnCell(complaintData) {
            return complaintData.visit_date ? moment(complaintData.visit_date).format(
              "h:mm A, DD/MM/YYYY"
            ) : '-';
          },
        size: 50,
      },
      {
        header: "Submitted At",
        accessorKey: "submitted_at",
        accessorFn: function renderReviewDate(reviewData) {
          return moment(reviewData.submitted_at).format("h:mm A, DD/MM/YYYY");
        },
        size: 50,
      },
      {
        header: "Branch",
        id: "branch",
        accessorFn: function renderBranchName(reviewData) {
          return reviewData.branch.name;
        },
        size: 50,
      },
      {
        header: "Division",
        accessorKey: "division",
      },
      {
        header: "Channel",
        accessorKey: "channel",
        size: 50,
      },
      {
        header: "Customer Name",
        id: "customerName",
        accessorFn: function renderCustomerName(reviewData) {
          return reviewData?.contacts?.name;
        },
        size: 50,
      },
      {
        header: "Phone Number",
        id: "customerPhone",
        accessorFn: function renderCustomerPhoneNum(reviewData) {
          return reviewData?.contacts?.phone;
        },
        size: 50,
      },
      {
        header: "",
        id: "archive-action",
        accessorFn: function renderReviewArchiveAction(reviewData) {
          return canArchiveReviews ? (
            <ArchiveReview reviewRecord={reviewData} />
          ) : null;
        },
        size: 50,
      },
    ],
    [canArchiveReviews]
  );

  return { reviewsTableColumns };
};
