import { Box, Container } from "@mui/material";
import { ReportsHubPage, ReportPageWithPrinting } from "../components/index";
import { useSearchParams } from "react-router-dom";

export const ReportsHubScreen = () => {
  const [searchParams] = useSearchParams();

  const reportType = searchParams.get("type");
  const reportWeek = searchParams.get("from_week");
  const reportMonth = searchParams.get("month");
  const reportYear = searchParams.get("year");

  const reportsHubPage = <ReportsHubPage />;
  const singlePrintableReportPage = (
    <ReportPageWithPrinting
      reportType={reportType}
      reportDate={{
        fromWeek: reportWeek,
        month: reportMonth,
        year: reportYear,
      }}
    />
  );

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Box display="flex" flexDirection="column" gap={5} mt={2}>
        {!reportType ? reportsHubPage : singlePrintableReportPage}
      </Box>
    </Container>
  );
};
