import { Box, Button, Tooltip } from "@mui/material";
import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import { ReviewComplaint } from "../../../outbound-feedback";
import { useMemo, useState } from "react";
import moment from "moment";
import {
  defaultReputationResponsesParams,
  useGetExternalReputationResponses,
} from "../../api/reputation-responses";
import { StarReputationResponse } from "./StarReputationResponse";
import {
  ReputationResponsePlatforms,
  ReputationResponseStatuses,
} from "../../types/reputation-reponses";
import UnattendedStatusSVG from "../../../../assets/icons/reputation-responses-table/unattended-status.svg";
import AsnweredStatusSVG from "../../../../assets/icons/reputation-responses-table/answered-status.svg";
import GoogleLogoSVG from "../../../../assets/logos/google.svg";
import TripadvisorLogoSVG from "../../../../assets/logos/tripadvisor.svg";
import { ArchiveReputationResponse } from "./ArchiveReputationResponse";
import { TableContainer } from "../shared/TableContainer";
import { OverallViewTable } from "../shared/OverallViewTable";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import {
  Brightness1,
  PanoramaFishEye,
  Star,
  StarBorder,
  Visibility,
} from "@mui/icons-material";
import { brandsColors, primaryColors } from "../../../../helpers/customColors";
import { DynamicSearchInput } from "../shared/DynamicSearchInput";
import SaveAs from "./SaveAs";
import { Filtration } from "../shared/Filtration";
import { useOverallViewFiltersState } from "../shared/useOverallViewFiltersState";
import { ReputationResponseTableRowDetails } from "./ReputationResponseTableRowDetails";

export function ReputationResponsesTablePage() {
  const [selectedComplaintsIDs, setSelectedComplaintsIDs] = useState([]);

  const {
    apiListArgsState,
    updatePageHandler,
    updatePageSizeHandler,
    initiateTypedSearchHandler,
  } = useListAPIsStateManagement({
    initialState: {
      ...defaultReputationResponsesParams,
    },
  });

  const {
    selectedFiltersState,
    updateSelectedBranches,
    updateSelectedDateRange,
    resetDateRange,
    removeSpecificBranch,
    updateArchivedFilterState,
    updateBookmarkedFilterState,
    updateReputationResponsePlatformFilterState,
    updateReputationResponseRatingFilterState,
    updateComplaintStatusFilterState,
    updateReputationResponseStatusFilterState,
  } = useOverallViewFiltersState();

  const dateFrom = selectedFiltersState.date_from;
  const dateTo = selectedFiltersState.date_to;
  const selectedBranches = selectedFiltersState.branches.join(",");
  const selectedPlatform = selectedFiltersState.reputationResponsePlatform;
  const selectedRating = selectedFiltersState.reputationResponseRating;

  const reputationResponsesListQueryParams = {
    ...apiListArgsState,
    search_text: undefined,
    search_type: undefined,
    date_from: apiListArgsState.search_text ? undefined : dateFrom,
    date_to: apiListArgsState.search_text ? undefined : dateTo,
    branches: apiListArgsState.search_text ? undefined : selectedBranches,
    archived: selectedFiltersState.archived,
    bookmarked: selectedFiltersState.bookmarked,
    platform: selectedPlatform,
    rating: selectedRating,
    status: apiListArgsState.search_text
      ? undefined
      : selectedFiltersState.reputationResponseStatus,
  };

  reputationResponsesListQueryParams[apiListArgsState.search_type] =
    apiListArgsState.search_text;

  const {
    data: reputationResponsesServerResponse,
    isLoading: isLoadingReputationResponses,
    isFetching: isFetchingReputationResponse,
    isError: reputationResponsesFetchingHasError,
  } = useGetExternalReputationResponses({
    params: { ...reputationResponsesListQueryParams },
  });

  const reputationResponsesTableColumns = useMemo(
    () => [
      {
        header: "",
        id: "star-action",
        accessorFn: function renderComplaintStarAction(reputationResponseData) {
          return (
            <StarReputationResponse
              reputationResponseData={reputationResponseData}
            />
          );
        },
        size: 50,
      },
      {
        header: "ID",
        id: "id",
        accessorKey: "id",
        size: 50,
      },
      {
        header: "Platform",
        id: "platform",
        accessorFn: function renderPlatformLogoColumnCell(
          reputationResponseData
        ) {
          return reputationResponseData.platform ===
            ReputationResponsePlatforms.Google ? (
            <img width={70} src={GoogleLogoSVG} alt="google-logo" />
          ) : (
            <img width={100} src={TripadvisorLogoSVG} alt="tripadvisor-logo" />
          );
        },
        size: 50,
      },
      {
        header: "Submitted on",
        accessorKey: "submitted_on",
        accessorFn: function renderComplaintDate(reputationResponseData) {
          return moment(reputationResponseData.submitted_on).format(
            "h:mm A, DD/MM/YYYY"
          );
        },
        size: 50,
      },
      {
        header: "Branch",
        id: "branch",
        accessorFn: function renderBranchName(reputationResponseData) {
          return reputationResponseData.branch.name;
        },
        size: 50,
      },
      {
        header: "Avatar",
        id: "avatar",
        accessorFn: function renderAvatarColumnCell(reputationResponseData) {
          return (
            <img
              src={reputationResponseData.avatar}
              width={50}
              height={50}
              style={{ borderRadius: "50%" }}
              alt="external-response-avatar"
            />
          );
        },
        size: 50,
      },
      {
        header: "Name or Username",
        id: "name_or_username",
        accessorFn: function renderUsernameColumnCell(reputationResponseData) {
          return reputationResponseData.name_or_username ?? "N/A";
        },
        size: 50,
      },
      {
        header: "Rating",
        id: "rating",
        accessorFn: function renderRatingColumnCell(reputationResponseData) {
          return renderReputationResponseStars(
            reputationResponseData.rating,
            reputationResponseData.platform
          );
        },
        size: 100,
      },
      {
        header: "",
        id: "actions-and-status",
        accessorFn: function renderComplaintResolvedState(
          reputationResponseData
        ) {
          let complaintStatusIcon = undefined;

          switch (reputationResponseData.status) {
            case ReputationResponseStatuses.Unattended: {
              complaintStatusIcon = (
                <Tooltip title="Unattended" placement="left">
                  <img
                    src={UnattendedStatusSVG}
                    style={{ width: "20px" }}
                    alt="unattended-reputation-response-img"
                  />
                </Tooltip>
              );
              break;
            }
            case ReputationResponseStatuses.Seen: {
              complaintStatusIcon = (
                <Tooltip title="Seen and not answered yet" placement="left">
                  <Visibility
                    sx={{
                      color: primaryColors.brand[600],
                    }}
                  />
                </Tooltip>
              );
              break;
            }
            case ReputationResponseStatuses.Answered: {
              complaintStatusIcon = (
                <Tooltip title="Answered" placement="left">
                  <img
                    src={AsnweredStatusSVG}
                    style={{ width: "20px" }}
                    alt="answered-reputation-response-img"
                  />
                </Tooltip>
              );
              break;
            }
            default: {
              complaintStatusIcon = null;
            }
          }

          return (
            <Box display="flex" alignItems="center" gap={2}>
              {complaintStatusIcon}
              <ArchiveReputationResponse
                reputationResponseData={reputationResponseData}
              />
            </Box>
          );
        },
        size: 50,
      },
    ],
    []
  );

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Filtration
        dateRangeFilter={{
          selectedDateRange: {
            date_from: selectedFiltersState.date_from,
            date_to: selectedFiltersState.date_to,
          },
          onChange: updateSelectedDateRange,
          resetDateRange,
        }}
        branchesOnlyFilter={{
          selectedBranches: selectedFiltersState.branches,
          onChange: updateSelectedBranches,
          removeSpecificBranch,
        }}
        archivedFilter={{
          archived: selectedFiltersState.archived,
          onChange: updateArchivedFilterState,
        }}
        bookmarkedFilter={{
          bookmarked: selectedFiltersState.bookmarked,
          onChange: updateBookmarkedFilterState,
        }}
        reputationResponsePlatformFilter={{
          platform: selectedFiltersState.reputationResponsePlatform,
          onChange: updateReputationResponsePlatformFilterState,
        }}
        reputationResponseRatingFilter={{
          rating: selectedFiltersState.reputationResponseRating,
          onChange: updateReputationResponseRatingFilterState,
        }}
        reputationResponseStatusFilter={{
          status: selectedFiltersState.reputationResponseStatus,
          onChange: updateReputationResponseStatusFilterState,
        }}
      />
      <TableContainer
        label={"External Reputation Responses"}
        tableActionsNextToName={
          <Box display="flex" alignItems="center" gap={2}>
            <SaveAs
              queryStringsForAPI={reputationResponsesListQueryParams}
              selectedReputationResponsesIDs={
                selectedComplaintsIDs === "all" ? [] : selectedComplaintsIDs
              }
              disabled={selectedComplaintsIDs?.length === 0}
            />
            <DynamicSearchInput
              initiateSearchCallback={({ type, value }) =>
                initiateTypedSearchHandler({ type, value })
              }
              resetSearchCallback={() =>
                initiateTypedSearchHandler({ type: "", value: "" })
              }
            />
          </Box>
        }
      >
        {!reputationResponsesServerResponse ? null : (
          <OverallViewTable
            columns={reputationResponsesTableColumns}
            data={reputationResponsesServerResponse.data.results}
            totalRecords={reputationResponsesServerResponse.data.count}
            isLoading={isLoadingReputationResponses}
            isError={reputationResponsesFetchingHasError}
            externalPaginationProps={{
              externalCurrentPage: apiListArgsState.page,
              updateExternalCurrentPage: updatePageHandler,

              externalPageSize: apiListArgsState.pageSize,
              updatePageSizeHandler,

              isFetching: isFetchingReputationResponse,
            }}
            uniqueColumnName="id"
            rowsSelectionHandling={{
              updaterHandler: function updateSelectedComplaintsIDs(
                latestSelectedRowsIDs
              ) {
                setSelectedComplaintsIDs(latestSelectedRowsIDs);
              },
            }}
            renderRowDetails={({ row }) => {
              return (
                <ReputationResponseTableRowDetails
                  reputationResponseDetails={row.original}
                  isExpanded={row.getIsExpanded()}
                />
              );
            }}
            withRowSelection
          />
        )}
      </TableContainer>
    </Box>
  );
}

function TestReviewComplaintDialog({ branchID, timestamp, complaintText }) {
  return (
    <GenericDialog
      dialogTitle="Save this response as a Complaint"
      triggerButton={<Button variant="outlined">Save as Complaint</Button>}
      maxWidth="xl"
    >
      <Box py={2}>
        <ReviewComplaint
          externalBranchID={31}
          externalTimestamp={timestamp}
          externalComplaintText={"WHYYYYYYYYYYYYYYYYYY"}
          externalUsername={"Samy George"}
          complaintOnly
        />
      </Box>
    </GenericDialog>
  );
}

export function renderReputationResponseStars(starsCount, platform) {
  return Array(5)
    .fill()
    .map(function renderRatingStars(data, index) {
      const filledStar =
        platform === ReputationResponsePlatforms.Google ? (
          <Star color="primary" sx={{ fontSize: 22 }} />
        ) : (
          <Brightness1 sx={{ fontSize: 22, color: brandsColors.TripAdvisor }} />
        );

      const emptyStar =
        platform === ReputationResponsePlatforms.Google ? (
          <StarBorder color="primary" sx={{ fontSize: 22 }} />
        ) : (
          <PanoramaFishEye
            sx={{ fontSize: 22, color: brandsColors.TripAdvisor }}
          />
        );

      return index + 1 <= starsCount ? filledStar : emptyStar;
    });
}
