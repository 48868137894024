import { Box } from "@mui/material";
import { AllMembersTabs } from "../components/members/AllMembersTabs";

export const MembersSubScreen = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <AllMembersTabs />
    </Box>
  );
};
