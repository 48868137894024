import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { externalColors } from "../../helpers/customColors";

export const IsLoadingContainer = ({
  isLoading,
  children,
  keepContentVisible = false,
  size,
}) => {
  if (keepContentVisible) {
    return (
      <Box position="relative">
        {isLoading && (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              color: externalColors.primaryColors.brand[500],
            }}
          />
        )}
        <Box sx={{ opacity: isLoading ? 50 : undefined }}>{children}</Box>
      </Box>
    );
  } else {
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="inherit"
          height={`${size || 100}%`}
        >
          <CircularProgress
            sx={{ color: externalColors.primaryColors.brand[500] }}
          />
        </Box>
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
};
