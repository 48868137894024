import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATION_WIDTH,
} from "../../../../contexts/NotificationContext";
import { useContext } from "react";

export const inviteMember = async (data) => {
  const requestPart = "users-invitations";
  const requestURL = `${API_URL}/${requestPart}`;

  return melior.post(requestURL, { ...data });
};

export const useInviteMember = ({ config } = {}) => {
  const { fireNotification } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    mutationFn: inviteMember,
    onSuccess: (data) => {
        
      queryClient.invalidateQueries(["invited-members"], { type: "active" });
      
      fireNotification({
        title: "Invitation sent successfully",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.everyone,
      });
      return data;
    },
    onError: (error) => {
      let errorMessage;
      error.response.status == 400
        ? (errorMessage = Object.values(error.response.data)[0])
        : (errorMessage = "An error occurred, please try again later");
      fireNotification({
        title: errorMessage,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.everyone,
      });
      return error.response;
    },
  });
};
