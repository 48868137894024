import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { ExistingMembersTable } from "./existing/ExistingMembersTable";
import { fontSizeVariations, fontWeightVariations } from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { InvitedMembersTable } from "./invited/InvitedMembersTable";

export const AllMembersTabs = ({}) => {
  const [allMembersInnerTab, setAllMembersInnerTab] = useState(0);

  const handleChange = (event, newAllMembersInnerTab) => {
    setAllMembersInnerTab(newAllMembersInnerTab);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={allMembersInnerTab}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          indicatorColor={""}
          sx={{ ...tabsStyles }}
        >
          <Tab
            label="Existing Members"
            {...a11yProps(0)}
            sx={{ ...tabStyles }}
          />
          <Tab
            label="Invited Members"
            {...a11yProps(1)}
            sx={{ ...tabStyles }}
          />
        </Tabs>
      </Box>
      <TabPanel value={allMembersInnerTab} index={0}>
        <ExistingMembersTable />
      </TabPanel>
      <TabPanel value={allMembersInnerTab} index={1}>
        <InvitedMembersTable />
      </TabPanel>
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index } = props;
  const isActive = value === index;

  return (
    <Box
      role="tabpanel"
      hidden={!isActive}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {isActive && <Box sx={{ p: 2 }}>{children}</Box>}
    </Box>
  );
}

const tabStyles = {
  fontSize: fontSizeVariations["text-sm"],
  fontWeight: fontWeightVariations.semiBold,
  textTransform: "capitalize",
  borderRight: `0.5px solid ${primaryColors.gray[300]}`,
  color: primaryColors.gray[600],
  "&.Mui-selected": {
    backgroundColor: primaryColors.brand[50],
    fontWeight: fontWeightVariations.bold,
    color: primaryColors.brand[700]
  },
};

const tabsStyles = {
  mt: 2,
  mb: 2,
  border: `1px solid ${primaryColors.gray[300]}`,
  borderRadius: 2,
  width: "fit-content",
  marginLeft: "1rem",
  height: "fit-content",
};
